import { NgModule } from "@angular/core";
import { S25OfficeHoursSliderComponent } from "./s25.office.hours.slider.component";
import { NgxSliderModule } from "ngx-slider-v2";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [CommonModule, NgxSliderModule],
    exports: [S25OfficeHoursSliderComponent],
    declarations: [S25OfficeHoursSliderComponent],
    providers: [S25OfficeHoursSliderComponent],
})
export class S25OfficeHoursSliderModule {}
