import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { VisualizationType } from "../s25.opt.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-visualization-buttons")
@Component({
    selector: "s25-ng-opt-visualization-buttons",
    template: `
        <button
            *ngIf="hasList"
            class="btn btn-default ngCompview"
            [ngClass]="{ 'btn-primary': selected === 'list' }"
            (click)="vizChange.emit('list')"
        >
            List
        </button>
        <button
            *ngIf="hasCalendar"
            class="btn btn-default ngCompview"
            [ngClass]="{ 'btn-primary': selected === 'calendar' }"
            (click)="vizChange.emit('calendar')"
        >
            Calendar
        </button>
        <button
            *ngIf="hasAvailability"
            class="btn btn-default ngCompview"
            [ngClass]="{ 'btn-primary': selected === 'availability' }"
            (click)="vizChange.emit('availability')"
        >
            Availability
        </button>
        <button
            *ngIf="hasAvailabilityWeekly"
            class="btn btn-default ngCompview"
            [ngClass]="{ 'btn-primary': selected === 'availabilityWeekly' }"
            (click)="vizChange.emit('availabilityWeekly')"
        >
            Availability Weekly
        </button>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptVisualizationButtonsComponent {
    @Input() hasList: boolean = false;
    @Input() hasCalendar: boolean = false;
    @Input() hasAvailability: boolean = false;
    @Input() hasAvailabilityWeekly: boolean = false;
    @Input() selected: VisualizationType;
    @Output() vizChange = new EventEmitter<VisualizationType>();
}
