import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { AvailService } from "../../services/avail.service";
import { UserprefService } from "../../services/userpref.service";
import { PreferenceService } from "../../services/preference.service";
import { ChangeContext, Options } from "ngx-slider-v2";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-office-hours-slider")
@Component({
    selector: "s25-ng-office-hours-slider",
    template: `
        <div
            *ngIf="isInit"
            class="office-hours-slider"
            [ngClass]="{ 'has-label': hasLabel && label }"
            aria-label="Availability slider. Adjust thumbs to set hours for availability view."
            tabindex="0"
        >
            <label *ngIf="hasLabel && label"> {{ label }}: </label>
            <ngx-slider
                [(value)]="start"
                [(highValue)]="end"
                [options]="sliderOptions"
                (userChange)="forceSliderMove()"
                (userChangeEnd)="changeHandler($event)"
            ></ngx-slider>
        </div>
    `,
    styles: `
        s25-ng-office-hours-slider .office-hours-slider {
            display: flex;
            gap: 1em;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 4px;
            height: 100%;
        }

        s25-ng-office-hours-slider .office-hours-slider.has-label {
            min-width: 150px;
        }

        s25-ng-office-hours-slider .office-hours-slider .ngx-slider {
            margin: 0;
            cursor: pointer;
        }

        s25-ng-office-hours-slider .office-hours-slider .ngx-slider .ngx-slider-pointer {
            width: 10px;
            height: 10px;
            top: -3px;
            border: 2px solid #277abe;
            background-color: white;
        }

        s25-ng-office-hours-slider .office-hours-slider .ngx-slider .ngx-slider-selection {
            background-color: #277abe;
        }

        s25-ng-office-hours-slider .office-hours-slider .ngx-slider .ngx-slider-pointer:after {
            display: none;
        }

        s25-ng-office-hours-slider .office-hours-slider .ngx-slider span {
            font-size: 0.9em;
            color: inherit;
        }

        s25-ng-office-hours-slider .office-hours-slider .ngx-slider-bubble {
            bottom: 4px;
        }

        s25-ng-office-hours-slider .ngx-slider .ngx-slider-bar-wrapper {
            margin: 0;
            padding: 0;
            height: 4px;
        }

        s25-ng-office-hours-slider .office-hours-slider > label {
            text-align: center;
            margin-bottom: 0;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OfficeHoursSliderComponent implements OnInit {
    @Input() action?: (start: number, end: number) => void; // ! Use @Output for TS components
    @Input() hasLabel?: boolean = false;
    @Input() label?: string = "Hours Shown";
    @Input() start: number = 0;
    @Input() end: number = 24;
    @Input() prefName: string = "OfficeHours"; // Can be set to null to not use pref

    @Output() onChange = new EventEmitter<{ start: number; end: number }>();
    @Output() startChange = new EventEmitter<number>();
    @Output() endChange = new EventEmitter<number>();

    isInit: boolean;
    prevStart: number = 0;
    prevEnd: number = 24;
    is24Hour: boolean;
    sliderOptions: Options = {
        ceil: 24,
        floor: 0,
        minRange: 1,
        maxLimit: 24,
        minLimit: 0,
        hideLimitLabels: true,
        translate: (value) => this.getLabel(value),
        ticksTooltip: (value) => this.getLabel(value),
    };
    changeHandler: (changeContext: ChangeContext) => void;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {
        elementRef.nativeElement.angBridge = this;
    }

    // Not sure why this is needed, but it is
    forceSliderMove() {
        this.changeDetector.detectChanges();
    }

    async ngOnInit() {
        const [preferences, is24Hour] = await Promise.all([
            this.prefName && PreferenceService.getPreferences([this.prefName]),
            UserprefService.getIs24HourTime(),
        ]);
        if (this.prefName) {
            this.start = PreferenceService.extractDisplayStartAsInt(preferences?.OfficeHours?.value);
            this.end = PreferenceService.extractDisplayEndAsInt(preferences?.OfficeHours?.value);
        }
        this.prevStart = this.start;
        this.prevEnd = this.end;
        this.is24Hour = is24Hour;

        // Debounce for keyboard users who have use arrow keys and cause a lot of events
        this.changeHandler = S25Util.debounce((context: ChangeContext) => this.onChangeHandler(context), 250);

        this.isInit = true;
        this.changeDetector.detectChanges();

        // Screen reader values
        const startThumb: HTMLElement = document.querySelector(".ngx-slider-pointer-min");
        const startAriaText: string = this.getLabel(this.start);
        if (startThumb && startAriaText) {
            startThumb.setAttribute("aria-valuetext", startAriaText + ". Adjust to set start time");
        }

        const endThumb: HTMLElement = document.querySelector(".ngx-slider-pointer-max");
        const endAriaText: string = this.getLabel(this.end);
        if (endThumb && endAriaText) {
            endThumb.setAttribute("aria-valuetext", endAriaText + ". Adjust to set end time");
        }
    }

    getLabel(hour: number) {
        if (this.is24Hour) return `${hour}:00`;
        // Time format requires AM/PM
        const time = String(hour % 12 || 12); // Midnight and midday are 12
        const label = hour % 24 < 12 ? "AM" : "PM"; // %24 because midnight is AM
        return `${time}${label}`;
    }

    async onChangeHandler(changeContext: ChangeContext) {
        if (this.start === this.prevStart && this.end === this.prevEnd) return;
        this.startChange.emit(this.start);
        this.endChange.emit(this.end);
        this.prevStart = this.start;
        this.prevEnd = this.end;
        const start = this.start;
        const end = this.end - 1; // -1 because end time is exclusive
        if (this.action) this.action(start, end);
        else {
            await this.updatePref(start, end);
            this.onChange.emit({ start, end });
        }
    }

    async updatePref(start: number, end: number) {
        if (!this.prefName) return;
        const officeHours = await PreferenceService.getPreferences([this.prefName]);
        const existingOfficeHours = S25Util.propertyGet(officeHours, "value");
        const newOfficeHours = PreferenceService.formOfficeHours(start, end, 0, existingOfficeHours);
        await PreferenceService.setPreference(this.prefName, newOfficeHours);
    }
}
